<template>
  <b-row class="justify-content-center">
    <b-col lg="12">
      <b-row class="justify-content-center mb-1">
        <b-col cols="12">
          <h5 class="text-dark fw-bold-700 px-1 mb-2">
            Pilih Lokasi SKU <span class="text-danger">*</span>
          </h5>
          <div
            v-for="(warehouse, index) in warehouses"
            :key="index"
          >
            <div class="d-flex justify-content-between px-1 mb-1">
              <h6 class="text-dark fw-bold-700">
                {{ warehouse.name }}
              </h6>
              <div class="custom-control-success custom-control custom-checkbox">
                <input
                  :id="`yes-${index}`"
                  ref="warehouseId"
                  v-model="formPayload.initial_stocks[index].warehouse_uuid"
                  class="custom-control-input"
                  type="checkbox"
                  :value="warehouse.uuid"
                  :checked="checkedWarehouse && checkedWarehouse.includes(warehouse.uuid) == true ? `${warehouse.uuid}` : ''"
                  :disabled="formPayload.initial_stocks[index].stock > 0"
                  @change="isRadioChecked"
                  @click="uncheckWarehouse(index, warehouse.name)"
                >
                <label
                  class="custom-control-label"
                  :for="`yes-${index}`"
                >
                  Ya
                </label>
              </div>
            </div>
          </div>
          <!-- <small
            v-if="messages.warehouses_uuid && Array.isArray(messages.warehouses_uuid) && messages.warehouses_uuid.length > 0"
            class="text-error fw-bold-500 size12"
          >{{ messages.warehouses_uuid.join(', ') }}</small> -->
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    // eslint-disable-next-line vue/prop-name-casing
    formData: {
      type: Object,
    },
    messages: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    isEceran: {
      type: Boolean,
      default: false,
    },
    arrChecked: {
      type: Array,
    },
    warehouseBatch: {
      type: '',
    },
  },
  data() {
    return {
      formPayload: {
        initial_stocks: [
        ],
      },
      formPayloadEceran: {
        initial_stocks: {
          warehouse_uuid: '',
          note: '',
          stock: 0,
        },
      },
      options: [],
      uomName: '',
      showTooltip: false,
      warehouses: [],
      checkedWarehouse: [],
      formPayloadCheck: [],
      listWarehouses: [],
      listFormData: [],
    }
  },
  watch: {
    warehouseBatch: {
      handler(value) {
        this.listWarehouses = value
        this.getData()
      },
      deep: true,
    },
    formData: {
      handler(value) {
        this.listFormData = []
        for (let index = 0; index < value.initial_stocks.length; index++) {
          const element = value.initial_stocks[index]
          if (element.type == 'warehouse') {
            this.listFormData.push(element)
          }
        }
        // this.listFormData = value
      },
      deep: true,
    },
    '$store.state.parentProduct.uom': function (value) {
      this.uomName = value
    },
    arrChecked(value) {
      this.checkedWarehouse = value
    },
    formPayload: {
      handler(value) {
        this.$emit('initialStocks', value.initial_stocks)
        this.$store.commit('parentProduct/setInitialStocks', value.initial_stocks)
      },
      deep: true,
    },
    formPayloadEceran: {
      handler(value) {
        this.$store.commit('parentProduct/setInitialStocks', {
          warehouse_uuid: value.initial_stocks.warehouse_uuid,
        })
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$route.name === 'master-product.parent' || this.$route.name === 'master-product.detail') {
      this.getData()
    }
    if (this.$route.name === 'master-product.retail' || this.$route.name === 'master-product-eceran.detail') {
      this.getDataEceran()
    }
  },
  methods: {
    uncheckWarehouse(index, name) {
      if (this.formPayload.initial_stocks[index].stock > 0) {
        this.$swal({
          icon: 'warning',
          title: 'Oops!',
          text: `Lokasi SKU ${name} tidak bisa diuncheck karena masih ada sisa stok`,
        })
      }
    },
    isRadioChecked() {
      // const that = this
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.$refs.warehouseId) {
        if (Object.hasOwnProperty.call(this.$refs.warehouseId, key)) {
          const element = this.$refs.warehouseId[key]
          if (element.checked === true) {
            this.formPayload.initial_stocks[key].warehouse_uuid = element.value
            this.formPayloadCheck.push(element.value)
          } else {
            delete this.formPayload.initial_stocks[key].warehouse_uuid
            this.removeA(this.formPayloadCheck, element.value)
          }
        }
      }
      const items = [...new Set(this.formPayloadCheck)]
      this.$emit('getWarehouseUuid', items)
      // for (let index = 0; index < this.warehouses.length; index++) {
      // }
    },
    removeA(arr) {
      // eslint-disable-next-line prefer-rest-params
      let what; const a = arguments; let L = a.length; let
        ax
      while (L > 1 && arr.length) {
        // eslint-disable-next-line no-plusplus
        what = a[--L]
        // eslint-disable-next-line no-cond-assign
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1)
        }
      }
      return arr
    },
    getData() {
      if (this.$route.params.id) {
        const array = []
        let diff = null

        diff = this.listWarehouses.filter(item => !this.listFormData.some(item2 => item2.type === 'warehouse' && item2.uuid === item.uuid))
        for (let index = 0; index < this.listWarehouses.length; index++) {
          const element = this.listWarehouses[index]
          const itemsInitialStock = this.listFormData[index]
          if (itemsInitialStock && itemsInitialStock.type == 'warehouse') {
            array.push(itemsInitialStock)
          }
        }

        if (diff.length) {
          for (let index = 0; index < diff.length; index++) {
            const element = diff[index]
            array.push({ ...element, stock: 0 })
          }
        }

        try {
          this.warehouses = array
        // eslint-disable-next-line no-empty
        } catch (error) {}
      } else {
        this.warehouses = this.listWarehouses
      }

      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.warehouses.length; index++) {
        const item = (this.listFormData && this.listFormData.length > 0 && this.listFormData[index]) || null
        this.formPayloadCheck.push(item?.uuid || '')
        if (item && item.type === 'warehouse') {
          this.formPayload.initial_stocks.push(
            {
              warehouse_uuid: item.uuid,
              note: '',
              stock: item.stock || 0,
            },
          )
        } else {
          this.formPayload.initial_stocks.push(
            {
              warehouse_uuid: '',
              note: '',
              stock: 0,
            },
          )
        }
      }
      this.checkedWarehouse = this.formPayloadCheck
    },
    getDataEceran() {
      this.$store.dispatch('warehouse/getData', { params: { per_page: 50 } }).then(result => {
        if (this.$route.params.id) {
          const arr1 = result.data.data.data.filter(o1 => this.formData.initial_stocks.some(o2 => o1.uuid === o2.uuid))
          const arr2 = result.data.data.data.filter(o1 => !this.formData.initial_stocks.some(o2 => o1.uuid === o2.uuid))

          try {
            Array.prototype.push.apply(arr1, arr2)
            this.warehouses = arr1
          // eslint-disable-next-line no-empty
          } catch (error) {}
        } else {
          this.warehouses = result.data?.data?.data
        }

        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.warehouses.length; index++) {
          const item = (this.formData && this.formData.initial_stocks.length > 0 && this.formData.initial_stocks[index]) || null
          this.formPayloadCheck.push(item?.uuid || '')
          if (item) {
            this.formPayload.initial_stocks.push(
              {
                warehouse_uuid: item.uuid,
                note: '',
                stock: item.stock || 0,
              },
            )
          } else {
            this.formPayload.initial_stocks.push(
              {
                warehouse_uuid: '',
                note: '',
                stock: 0,
              },
            )
          }
        }
        this.checkedWarehouse = this.formPayloadCheck
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
.input-group-append {
  border: none !important;
  .input-group-text {
    background: #EFF1F5;
  }
}

#talkbubble2 {
  position: absolute;
  top: 20px;
  right: 0;
  background: #334155;
  border-radius: 8px 0px 8px 8px;
  width: 320px;
  height: auto;
  padding: 1rem;
  z-index: 10;

  .title {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .content-tooltip {
    font-size: 14px;
    color: #E2E8F0;
    margin-bottom: 10px;
  }

  .footer {
    button {
      background-color: #4A5A71 !important;
      border: 1px solid #4A5A71 !important;
      border-radius: 6px;
    }
  }
}
</style>
